<template>
<!-- iframe will be inserted as a child element -->
<div id="sumsub-websdk-container"></div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import authHeader from '../../utils/auth-header'
import snsWebSdk from '@sumsub/websdk'

export default {
  name: 'Verify',
  methods: {
    async createAccessToken () {
      const config = {}
      config.baseURL = process.env.VUE_APP_API_BASE_URL
      const method = 'get'
      const url = '/v1/api/auth/kycToken'
      const headers = authHeader()

      config.method = method
      config.url = url
      config.headers = headers
      config.data = null
      const response = await axios(config)
      return response.data.kycToken
    },
    getNewAccessToken () {
      return Promise.resolve(createAccessToken) // get a new token from your backend
    },
    /**
         * @param accessToken - access token that you generated on the backend in Step 2
         * @param applicantEmail - applicant email (not required)
         * @param applicantPhone - applicant phone, if available (not required)
         * @param customI18nMessages - customized locale messages for current session (not required)
         */
    launchWebSdk (accessToken, applicantEmail, applicantPhone, customI18nMessages) {
      const snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => this.getNewAccessToken()
      )
        .withConf({
          lang: 'bg', // language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          i18n: customI18nMessages, // JSON of custom SDK Translations
          uiConf: {
            // customCss: 'https://url.com/styles.css'
            // URL to css file in case you need change it dynamically from the code
            // the similar setting at Customizations tab will rewrite customCss
            // you may also use to pass string with plain styles `customCssStr:`
          }
        })
        .withOptions({
          addViewportTag: false,
          adaptIframeHeight: true
        })
        .on('stepCompleted', (payload) => {
        })
        .on('onError', (error) => {
          console.error('error launching SDK', error)
        })
        .onMessage((type, payload) => {
        })
        .build()

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch('#sumsub-websdk-container')
    }
  },
  mounted: async function () {
    const accessToken = await this.createAccessToken()
    this.launchWebSdk(accessToken)
  }
}
</script>
